<template>
  <div>
    <!--    主体律师列表-->
    <div class="app-container">
      <el-row
        v-loading="loading"
        element-loading-text="加载中"
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
        stripe
      >
        <el-col :span="3">
          <b>律师列表</b>
        </el-col>

        <el-col :span="3">
          <el-button icon="el-icon-plus" size="small" type="success" @click="handleAdd">添加</el-button>
        </el-col>
        <!-- 律师姓名搜索框 -->
        <el-col :span="3" :push="3" > 律师姓名:  </el-col>
        <el-col :span="4" :push="1" >
          <el-input
            placeholder="请输入律师姓名"
            v-model="searchLawyerName"
            size="small"
          >
          </el-input>
        </el-col>

        <el-col :span="6" :push="1">

          <el-button size="small" icon="el-icon-search" type="primary" @click="handleSearch">搜索</el-button>
          <el-button size="small" @click="clearInput">重置</el-button>

        </el-col>
      </el-row>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background: '#eee', color: '#333' }"
      >
        <el-table-column prop="name" label="姓名" align="center" ></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" ></el-table-column>
        <el-table-column prop="practicingYears" label="执业年限" align="center"></el-table-column>
        <el-table-column prop="practiceno" label="执业证编号" align="center" ></el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="300px">
          <template slot-scope="scope">
            <el-button
              size="mini"

              icon="el-icon-more"
              @click="handleDetail(scope.$index, scope.row)"
            >
              详情</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >
              编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-refresh"
              @click="handleRest(scope.$index, scope.row)"
            >
              重置密码</el-button
            >
<!--            <el-button-->
<!--              size="mini"-->
<!--              type="danger"-->
<!--              icon="el-icon-delete"-->
<!--              @click="handleDelete(scope.$index, scope.row)"-->
<!--            >-->
<!--              删除</el-button-->
<!--            >-->
          </template>
        </el-table-column>
      </el-table>
      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>
    </div>

    <!--    屏幕打印检查数据-->
<!--    <h1>修改：{{editForm}}</h1>-->
<!--    <h2>添加：{{addForm}}</h2>-->

    <!--    详情探弹窗-->
    <el-dialog
      :fullscreen="true"
      title="律师详情"
      align="center"
      :visible.sync="ifDetail"
      width="50%">
      <el-row>
        <el-col :span="6" >
          <h3>律师姓名</h3>
          <span>{{currentData.name}}</span>
        </el-col>
        <el-col :span="6" :push="6" >
          <h3>所属律所</h3>
          <span>{{currentData.dname}}</span>
        </el-col>
      </el-row>

      <el-divider></el-divider>

      <el-row>
        <el-col :span="6" >
          <h3>电话号</h3>
          <span>{{currentData.phone}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>性别</h3>
          <span>{{currentData._gender}}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>执业编号</h3>
          <span>{{currentData.practiceno}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>执业年限</h3>
          <span>{{currentData.practicingYears}}</span>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>政治面貌</h3>
          <span>{{currentData._politicalStatus}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>民族</h3>
          <span>{{currentData._nationality}}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>学位</h3>
          <span>{{currentData._degree}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>专业</h3>
          <span>{{currentData.specialty}}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>身份证号</h3>
          <span>{{currentData.idCard}}</span>
        </el-col>
        <!--        <el-col :span="6" :push="6">-->
        <!--          <h3>密码</h3>-->
        <!--          <span>{{currentData.password}}</span>-->
        <!--        </el-col>-->
      </el-row>
    </el-dialog>

    <!--    修改信息弹窗-->
    <el-dialog
      :fullscreen="true"
      title="修改信息"
      align="center"
      :visible.sync="ifEdit"
      width="50%">
      <div style="width:60%">
        <el-form ref="editForm" :model="editForm" :rules="rules" label-width="15%">
          <el-form-item label="律师姓名" prop="name">
            <el-input v-model="editForm.name" maxlength="10"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="所属律所" >
            <el-input v-model="editForm.dname" disabled></el-input>
          </el-form-item>
          <el-form-item label="政治面貌">


            <el-select style="width: 50%" v-model="editForm.politicalStatus">
              <el-option
                v-for="item in political_status_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>



          </el-form-item>
          <el-form-item label="性别">
            <el-select style="width: 50%" v-model="editForm.gender" placeholder="请选择">
              <el-option
                v-for="item in gender_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证" prop="idCard">
            <el-input v-model="editForm.idCard" maxlength="18"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="editForm.phone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="执业证编号" prop="practiceno">
            <el-input v-model="editForm.practiceno" maxlength="17"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="执业年限">
            <!--          <el-input v-model="editForm.practicing_years"></el-input>-->
            <el-input-number v-model="editForm.practicingYears" :min="1" :max="70" ></el-input-number>
          </el-form-item>
          <el-form-item label="学历">
            <!--          <el-input v-model="editForm.degree"></el-input>-->
            <el-select style="width: 50%" v-model="editForm.degree" placeholder="请选择">
              <el-option
                v-for="item in degree_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业">
            <el-input v-model="editForm.specialty" maxlength="10"  show-word-limit></el-input>
          </el-form-item>

<!--          <el-form-item label="密码">-->
<!--            <el-input v-model="editForm.password"></el-input>-->
<!--          </el-form-item>-->
        </el-form>
        <span slot="footer" class="dialog-footer">
             <el-button size="small" type="primary" @click="beforeEdit('editForm')">确定修改</el-button>
            <el-button size="small" @click="editCancel">取消</el-button>
          </span>
      </div>



    </el-dialog>

    <!--    新增律师弹窗-->
    <el-dialog
      :fullscreen="true"
      title="新增律师"
      align="center"
      :visible.sync="ifAdd"
      width="50%">
      <div style="width: 50%">

        <el-form ref="addForm" :model="addForm" :rules="rules" label-width="15%">
          <el-form-item label="律师姓名" prop="name" >
            <el-input v-model="addForm.name" maxlength="10"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="所属律所" >
            <el-input :placeholder="dname" :disabled="true" ></el-input>
          </el-form-item>

          <el-form-item label="政治面貌">

              <el-select style="width: 50%" v-model="addForm.politicalStatus" placeholder="请选择">
                <el-option
                  v-for="item in political_status_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>

          </el-form-item>

          <el-form-item label="民族">
            <el-select style="width: 50%" v-model="addForm.nationality" placeholder="请选择">
              <el-option
                v-for="item in nationality_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="性别">
            <el-select style="width: 50%" v-model="addForm.gender" placeholder="请选择">
              <el-option
                v-for="item in gender_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份证" prop="idCard">
            <el-input v-model="addForm.idCard" maxlength="18"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="addForm.phone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="执业证编号" prop="practiceno">
            <el-input v-model="addForm.practiceno" maxlength="17"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="执业年限" >
            <el-input-number v-model="addForm.practicingYears" :min="1" :max="70"></el-input-number>
          </el-form-item>
          <el-form-item label="学历">
            <el-select style="width: 50%" v-model="addForm.degree" placeholder="请选择">
              <el-option
                v-for="item in degree_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业">
            <el-input v-model="addForm.specialty" maxlength="10"  show-word-limit></el-input>
          </el-form-item>


        </el-form>
        <span slot="footer" class="dialog-footer">
             <el-button size="small" type="primary" @click="berforeSubmit('addForm')">确定新增</el-button>
            <el-button size="small" @click="addCancel">取消</el-button>
      </span>
      </div>


    </el-dialog>

  </div>
</template>

<script>

import { request } from '@/network/network'
import { idCard, phone, practiceno, password} from '@/utils/rules'
import { user_politicalStatus_options,user_politicalStatus_dict,user_degree_dict, user_degree_options ,
  user_gender_options,  user_gender_dict, user_nationality_options , user_nationality_dict, } from '@/utils/dict'

export default {

  data() {
    return {
      loading: true,
      dname:"",
      searchLawyerName:"",
      searchMap:"",
      tableData: [],
      total: 0,
      currentPage: 0,

      currentData:'',  //每行的数据
      //新增律师表单数据
      addForm:{
        name:"",
        did:"",
        politicalStatus:'',
        nationality:'',
        degree:'',
        specialty:"",
        id_card:"",
        gender:"",
        phone:"",
        practiceno:"",
        practicingYears:10,
      },
      //修改信息表单数据
      editForm:{},
      rules:{
        name: [
          { required: true, message: '律师姓名不能为空', trigger: 'blur' },
          { min: 1, max: 10, message: '不能多于10字', trigger: 'blur' }
        ],
        idCard:idCard,
        phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
        practiceno:practiceno,
      },
      ifAdd:false,
      ifEdit: false, //是否弹出详情框
      ifDetail:false,
      //政治面貌、性别、学历选择框
      political_status_options: user_politicalStatus_options,
      gender_options: user_gender_options,
      degree_options: user_degree_options,
      nationality_options: user_nationality_options,
      adminInformation: JSON.parse(sessionStorage.getItem("information"))
    }
  },
  methods: {
    //分页插件绑定事件
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.getLawyers(this.searchMap,val)
    },
    // 重置搜索框
    clearInput() {
      this.searchLawyerName = ""
      this.searchMap=""
      this.getLawyers(this.searchMap);
    },
    //搜索按钮
    handleSearch(){
      this.searchMap = this.searchLawyerName
      this.getLawyers(this.searchMap)
    },
    //详情按钮
    handleDetail(index, row) {
      this.ifDetail = true
      this.currentData = row

    },
    // 新增按钮
    handleAdd(index,row){
      this.ifAdd = true
    },
    addCancel(){
      this.ifAdd = false
      this.addForm = {practicingYears:10}
    },
    berforeSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '请稍等',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          this.checkAdd(this.addForm.practiceno)
            .then(res => {
              loading.close();
              if(res.data.length == 0){
                this.addSubmit()
              }
              else{
                this.$message({
                  message: "新增律师失败,该执业证号已存在！",
                  type: "error",
                  duration: 2000,
                });
              }
            })
            .catch((err) => {
              loading.close();
              this.$message({
                message: "发生错误，新增律师失败！",
                type: "error",
                duration: 1000,
              });
              this.getLawyers();
            });

        } else {

          return false;
        }
      });

    },
    // 确定新增
    addSubmit(index,row){

      this.addForm.did = JSON.parse(JSON.stringify(this.adminInformation.did))
      const loading = this.$loading({
        lock: true,
        text: '请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.addLawyer(this.addForm)
        .then((res) => {
          loading.close();
          this.$message({
            message: "新增成功！",
            type: "success",
            duration: 1000,
          });
          this.getLawyers();
          this.addForm = {practicingYears:10}
          this.ifAdd = false
        })
        .catch((err) => {
          loading.close();
          this.$message({
            message: "发生错误，新增失败！",
            type: "error",
            duration: 1000,
          });
          this.getLawyers();
          this.ifAdd = false
        });
    },

    // 编辑按钮

    //将该条数据传入editForm编辑信息表单中显示
    handleEdit(index,row){
      this.ifEdit = true
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editForm.password = null
    },
    beforeEdit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.editSubmit()

        } else {
          return false;
        }
      });

    },
    //确定编辑
    editSubmit() {
      this.editLawyer(this.editForm)
        .then((res) => {
          this.$message({
            message: "修改成功！",
            type: "success",
            duration: 1000,
          });
          this.getLawyers();
          this.ifEdit = false
        })
        .catch((err) => {
          this.$message({
            message: "发生错误，修改失败！",
            type: "error",
            duration: 1000,
          });
          this.getLawyers();
          this.ifEdit = false
        });
    },
    editCancel() {
      this.getLawyers();
      this.ifEdit = false;
      this.addForm = {}

    },
    // // InputNumber 计数器,选择学时
    // handleChange(value) {
    //   this.editForm.practicingYears
    // },
    // 重置密码按钮
    handleRest(index,row){
      this.$confirm("是否要重置该律师密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.restPassword(row.id)
            .then((res) => {
              this.$message({
                message: "重置成功！",
                type: "success",
                duration: 1000,
              });
              this.getLawyers();
            })
            .catch((err) => {
              this.$message({
                message: "发生错误，重置失败！",
                type: "error",
                duration: 1000,
              });
              this.getLawyers();
            });
        })
        .catch(() => {
          this.$message({
            message: "操作取消！",
            type: "info",
            duration: 1000,
          });
        });
    },

    // 删除按钮
    handleDelete(index, row) {
      this.$confirm("是否要进行该删除操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteLawyer(row.id)
            .then((res) => {
              this.$message({
                message: "删除成功！",
                type: "success",
                duration: 1000,
              });
              this.getLawyers();

            })
            .catch((err) => {
              this.$message({
                message: "发生错误，删除失败！",
                type: "error",
                duration: 1000,
              });
              this.getLawyers();

            });
        })
        .catch(() => {
          this.$message({
            message: "操作取消！",
            type: "info",
            duration: 1000,
          });
        });
    },

    getLawyers(name, page){
      return request({
        url: '/user/users',
        method:'get',
        params:{
          did:this.adminInformation.did ,
          name:name,
          page: page,
        }
      }).then(res => {
        this.total = res.data.total
        let temp = res.data.list
        this.tableData = temp.map(item => {
          item._nationality = user_nationality_dict[item.nationality];
          item._gender = user_gender_dict[item.gender];
          item._politicalStatus = user_politicalStatus_dict[item.politicalStatus];
          item._degree = user_degree_dict[item.degree];
          return item
        })
        this.loading = false
      })
    },
    addLawyer(lawyer){
      return request({
        url: '/user/addUser',
        method: 'post',
        data: lawyer
      })
    },

    editLawyer(lawyer){
      return request({
        url: '/user/updateUser',
        method: 'post',
        data: lawyer
      })
    },
    deleteLawyer(id){
      return request({
        url: '/user/deleteUser',
        method: 'get',
        params: {
          id: id
        }
      })
    },
    restPassword(id){
      return request({
        url: '/user/restPassword',
        method: 'get',
        params: {
          id: id
        }
      })
    },
    checkAdd(practiceno){
      return request({
        url: '/user/checkAdd',
        method: 'get',
        params: {
          practiceno: practiceno
        }
      })

    },

  },

  created() {
    this.getLawyers();
    this.dname = this.adminInformation.dname
    this.did = this.adminInformation.did

  },
};
</script>
<style scoped>
.input-width {
  width: 80%;
}
</style>

